var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import styled from "styled-components";
export var MainWidgetWrapper = styled.div(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  @import url(\"https://fonts.googleapis.com/css2?family=Inter:wght@700&display=swap\");\n  padding: 10px;\n  outline: 1px solid black;\n  max-width: 181px;\n  font-family: \"Inter\", sans-serif;\n  box-sizing: border-box;\n"], ["\n  @import url(\"https://fonts.googleapis.com/css2?family=Inter:wght@700&display=swap\");\n  padding: 10px;\n  outline: 1px solid black;\n  max-width: 181px;\n  font-family: \"Inter\", sans-serif;\n  box-sizing: border-box;\n"])));
export var TextPart = styled.div(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  padding-top: 8px;\n  h2 {\n    color: #232d5c;\n    font-size: 18px;\n    font-weight: bold;\n    line-height: 1.4px;\n    margin: 0;\n    display: inline-block;\n  }\n"], ["\n  padding-top: 8px;\n  h2 {\n    color: #232d5c;\n    font-size: 18px;\n    font-weight: bold;\n    line-height: 1.4px;\n    margin: 0;\n    display: inline-block;\n  }\n"])));
export var ImgWrapper = styled.div(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n  width: 140px;\n  margin-top: 4px;\n  svg {\n    width: 100%auto;\n  }\n"], ["\n  width: 140px;\n  margin-top: 4px;\n  svg {\n    width: 100%auto;\n  }\n"])));
export var RatingPartStyled = styled.div(templateObject_4 || (templateObject_4 = __makeTemplateObject(["\n    display: flex;\n    width: 100%;\n    justify-content: space-between;\n    align-items: center;\n    border-bottom: 1px solid #EEEEF1;\n    padding-bottom: 8px;\n"], ["\n    display: flex;\n    width: 100%;\n    justify-content: space-between;\n    align-items: center;\n    border-bottom: 1px solid #EEEEF1;\n    padding-bottom: 8px;\n"])));
export var RatingTextWrapper = styled.span(templateObject_5 || (templateObject_5 = __makeTemplateObject(["\n    color: #EF7D00;\n    font-weight: bold;\n    font-size: 24px;\n    line-height: 1.2;\n\n"], ["\n    color: #EF7D00;\n    font-weight: bold;\n    font-size: 24px;\n    line-height: 1.2;\n\n"])));
var templateObject_1, templateObject_2, templateObject_3, templateObject_4, templateObject_5;
