import { h } from "preact";
import useFetch from "use-http";
import { ImgWrapper, MainWidgetWrapper, RatingPartStyled, RatingTextWrapper, TextPart, } from "./MainWidget.styled";
import Rating from "../../components/Rating";
export var MainWidget = function (_a) {
    var itemID = _a.itemID, itemType = _a.itemType;
    var _b = useFetch("https://api.studenten.nl/api/review/" + itemType + "/" + itemID + "?onlyRating=true", {}, []).data, data = _b === void 0 ? { rating: 0 } : _b;
    return (h(MainWidgetWrapper, null,
        Boolean(data === null || data === void 0 ? void 0 : data.rating) && (h(RatingPartStyled, null,
            h(Rating, { rating: data.rating }),
            h(RatingTextWrapper, null, data.rating.toFixed(1).replace(".", ",")))),
        h(TextPart, null,
            h("h2", null, "Provided by "),
            h(ImgWrapper, null,
                h("svg", { width: "140", height: "36", viewBox: "0 0 140 36", fill: "none", xmlns: "http://www.w3.org/2000/svg" },
                    h("path", { d: "M29.2498 6.74983L20.8122 5.72205e-06V4.49932H13.4996V4.49981C8.53715 4.49981 4.5 8.53721 4.5 13.4994C4.5 18.4621 8.53715 22.4993 13.4998 22.4993V22.5H20.2489C20.2489 22.4943 20.2499 22.4892 20.2499 22.4838C20.2499 20.1098 18.4013 18.1643 16.0689 17.9995H13.4998C11.0186 17.9995 8.9998 15.9806 8.9998 13.4994C8.9998 11.0182 11.0186 8.99936 13.4998 8.99936H20.8122V13.4999L29.2498 6.74983Z", fill: "#EF7D00" }),
                    h("path", { d: "M23.4343 11.4025L20.8126 13.4998V8.99996H13.4892C11.0132 9.00586 9.00025 11.022 9.00025 13.4995V13.4998H15.7611C20.7187 13.5059 24.7502 17.5404 24.7502 22.4994C24.7502 27.4621 20.713 31.4992 15.7503 31.4992H8.4375V36H15.7501C23.2058 36 29.2502 29.9558 29.2502 22.4999C29.2502 17.8999 26.9478 13.8399 23.4343 11.4025Z", fill: "#008237" }),
                    h("path", { d: "M29.2488 22.4844H29.2493V6.75069L23.4336 11.4032C26.9429 13.8377 29.2434 17.8914 29.2488 22.4844Z", fill: "#232D5C" }),
                    h("path", { d: "M15.7502 13.4993L9.0001 13.4995C9.0001 15.9807 11.0189 17.9996 13.5001 17.9996V17.9993H15.7588C18.2361 18.0042 20.25 20.0208 20.25 22.4994C20.25 24.9808 18.2314 26.9997 15.7502 26.9997V26.9994H8.43759V22.4999L0 29.2499L8.43759 36V31.4992H15.7502C20.7129 31.4992 24.75 27.4621 24.75 22.4994C24.75 17.5367 20.7129 13.4993 15.7502 13.4993Z", fill: "#66C2C7" }),
                    h("path", { d: "M5.81568 24.5975C2.30266 22.1605 0.000245958 18.1005 0 13.5011V29.25L5.81568 24.5975Z", fill: "#232D5C" }),
                    h("path", { d: "M5.81568 24.5975L8.43759 22.5002V26.9998L15.7502 27C18.2314 27 20.2502 24.9812 20.2502 22.4997H13.5001V22.4995C8.53745 22.4995 4.50005 18.4623 4.50005 13.4996C4.50005 8.53741 8.5372 4.50001 13.4999 4.50001V4.49952H20.8125V0.000205994H13.5001C6.04417 0.000205994 0 6.04413 0 13.5001C0 18.1 2.30241 22.1603 5.81568 24.5975Z", fill: "#E94190" }),
                    h("path", { d: "M127.776 7.3588V16.7227L125.55 7.3588H122.355V28.582H125.55V19.2182L127.776 28.582H130.971V7.3588H127.776ZM139.441 25.3875V28.582H132.281V7.3588H135.476V25.3875H139.441Z", fill: "#EF7D00" }),
                    h("path", { d: "M118.246 25.3895H121.242V28.584H118.246V25.3895Z", fill: "#E94190" }),
                    h("path", { d: "M39.7132 16.6342C38.5171 15.2566 37.4841 14.0669 37.4841 12.6084H37.4838V11.5306H37.4841C37.4841 10.9081 37.9888 10.4034 38.6113 10.4034C39.2336 10.4034 39.7383 10.9081 39.7383 11.5306V14.3193H42.9328V11.5306H42.933C42.933 9.14359 40.9983 7.20864 38.6113 7.20864C36.2243 7.20864 34.2893 9.14359 34.2893 11.5306V12.6084H34.2891C34.2891 15.2603 35.8893 17.103 37.3008 18.7291C38.6086 20.2351 39.738 21.5357 39.738 23.3329H39.7383V24.4109C39.7383 25.0332 39.2336 25.5379 38.6113 25.5379C37.9888 25.5379 37.4843 25.0332 37.4843 24.4109H37.4838V21.6223H34.2893V24.4109H34.2896C34.2896 26.7977 36.2245 28.7324 38.6113 28.7324C40.9981 28.7324 42.9328 26.7977 42.9328 24.4109V23.3329H42.932H42.9328C42.9328 20.3423 41.2224 18.3724 39.7132 16.6342ZM68.1331 11.6809H68.1329C68.1329 11.0584 67.6284 10.5537 67.0059 10.5537H65.9643V25.3876H67.0062C67.6284 25.3876 68.1331 24.8832 68.1331 24.2607V11.6809ZM71.3279 11.6809H71.3276V24.2607C71.3276 26.6474 69.3929 28.5821 67.0062 28.5821V28.5787L67.0059 28.5821H62.7698V7.35891H67.0059C69.3929 7.35891 71.3279 9.29387 71.3279 11.6809ZM58.3211 7.35891V24.4109C58.3211 25.0332 57.8167 25.5379 57.1941 25.5379C56.5716 25.5379 56.0672 25.0332 56.0672 24.4109V7.35891H52.8724V24.4109C52.8724 26.7977 54.8074 28.7324 57.1941 28.7324C59.5809 28.7324 61.5159 26.7977 61.5159 24.4109V7.35891H58.3211ZM107.58 10.5537V7.35891H100.078V28.5821H107.58V25.3876H103.272V19.4679H106.525V16.2734H103.272V10.5537H107.58ZM113.941 7.35891V16.7228L111.715 7.35891H108.52V28.5821H111.715V19.2183L113.941 28.5821H117.136V7.35891H113.941ZM80.0842 10.5537V7.35891H72.5818V28.5821H80.0842V25.3876H75.7765V19.4679H79.0288V16.2734H75.7765V10.5537H80.0842ZM99.1659 7.35891H90.551V10.5537H93.2612V28.5821H96.4557V10.5537H99.1659V7.35891ZM52.0177 7.35891H43.4028V10.5537H46.113V28.5821H49.3075V10.5537H52.0177V7.35891ZM89.6395 7.35891V28.5821H86.445L84.219 19.2183V28.5821H81.0245V7.35891H84.219L86.445 16.7228V7.35891H89.6395Z", fill: "#232D5C" }))))));
};
