import { h } from "preact";
import ReactRating from "react-rating";
import "./";
import { RatingWrapper, StarWrapper } from "./Rating.styled";
export var Rating = function (_a) {
    var rating = _a.rating;
    return (h(RatingWrapper, null,
        h(ReactRating, { initialRating: rating, className: "rating", fullSymbol: h(StarWrapper, null,
                h("svg", { width: "18", height: "17", viewBox: "0 0 18 17", fill: "none", xmlns: "http://www.w3.org/2000/svg" },
                    h("path", { d: "M8.13024 1.033C8.51342 0.357629 9.48658 0.357628 9.86976 1.033L11.9616 4.72001C12.1041 4.97108 12.3481 5.14834 12.6308 5.20623L16.7838 6.05636C17.5445 6.21208 17.8453 7.13762 17.3214 7.71075L14.4612 10.8396C14.2665 11.0526 14.1733 11.3394 14.2056 11.6263L14.6804 15.8387C14.7674 16.6103 13.9801 17.1823 13.2731 16.8612L9.4136 15.1079C9.15079 14.9885 8.84921 14.9885 8.5864 15.1079L4.72688 16.8612C4.0199 17.1823 3.23259 16.6103 3.31957 15.8387L3.79439 11.6263C3.82672 11.3395 3.73353 11.0526 3.53877 10.8396L0.678637 7.71075C0.154726 7.13762 0.45545 6.21208 1.21618 6.05636L5.36916 5.20623C5.65195 5.14834 5.89593 4.97108 6.03838 4.72002L8.13024 1.033Z", fill: "#DDDB00" }))), emptySymbol: h(StarWrapper, null,
                h("svg", { width: "18", height: "17", viewBox: "0 0 18 17", fill: "none", xmlns: "http://www.w3.org/2000/svg" },
                    h("path", { d: "M8.13024 1.033C8.51342 0.357629 9.48658 0.357628 9.86976 1.033L11.9616 4.72001C12.1041 4.97108 12.3481 5.14834 12.6308 5.20623L16.7838 6.05636C17.5445 6.21208 17.8453 7.13762 17.3214 7.71075L14.4612 10.8396C14.2665 11.0526 14.1733 11.3394 14.2056 11.6263L14.6804 15.8387C14.7674 16.6103 13.9801 17.1823 13.2731 16.8612L9.4136 15.1079C9.15079 14.9885 8.84921 14.9885 8.5864 15.1079L4.72688 16.8612C4.0199 17.1823 3.23259 16.6103 3.31957 15.8387L3.79439 11.6263C3.82672 11.3395 3.73353 11.0526 3.53877 10.8396L0.678637 7.71075C0.154726 7.13762 0.45545 6.21208 1.21618 6.05636L5.36916 5.20623C5.65195 5.14834 5.89593 4.97108 6.03838 4.72002L8.13024 1.033Z", fill: "#DDDB00", opacity: "0.4" }))), readonly: true })));
};
